<template>
  <div class="d-flex flex-column align-center">
    <ProcessLoader class="mb-16"/>

    <div class="mb-10 font-large titleMigration--text">
      PROCESSING...
    </div>

    <div class="font-normal descriptionMigration--text text-center">
      It will take about 20 minutes. Feel free to close this page, the migration will continue. We'll drop you an email once everything's set up and ready
    </div>
  </div>
</template>

<script>
import ProcessLoader from '@/components/process-loader/ProcessLoader.vue'

import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'MigrationProcessDesktopStepThree',
  components: { ProcessLoader },
  data: () => ({
    statusInterval: null
  }),
  methods: {
    setStatusInterval() {
      clearInterval(this.statusInterval)

      this.loadStatusMigration()

      this.statusInterval = setInterval(() => {
        this.loadStatusMigration()
      }, 10000)
    },
    loadStatusMigration() {
      this.$store.dispatch(authTypes.actions.GET_MIGRATION_STATUS_ACCOUNT)
    }
  },
  beforeMount() {
    this.setStatusInterval()
  },
  beforeDestroy() {
    clearInterval(this.statusInterval)
  }
}
</script>