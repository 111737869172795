<template>
  <div class="px-35 py-8 d-flex align-center migration-process-desktop-header">
    <div
      class="d-flex align-center migration-process-desktop-header-item font-title-process-migration"
      v-for="step in steps"
      :key="step.id"
    >
      <div
        class="mr-4 d-flex align-center white--text justify-center migration-process-desktop-header-item__circle"
        :class="{ 'migration-process-desktop-header-item__circle_active': step.id === activeStep.id }"
      >
        {{ step.id }}
      </div>

      <div :class="step.id === activeStep.id ? 'primary--text' : 'textProcessMigration--text'">
        {{ step.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MigrationProcessDesktopHeader',
  props: {
    steps: {
      type: Array,
      default: () => ([])
    },
    activeStep: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="scss">

.migration-process-desktop-header {
  background: var(--v-headerProcessMigration-base);
}

.migration-process-desktop-header-item {

  & + & {
    margin-left: 32px;
  }

  &__circle {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: var(--v-circleProcessMigration-base);

    &_active {
      background: var(--v-primary-base);
    }
  }
}
</style>