<template>
  <div class="my-30 mx-auto overflow-hidden migration-process-desktop">
    <MigrationProcessDesktopHeader
      :steps="Object.values(steps)"
      :activeStep="activeStep"
    />

    <div class="px-35 py-35">
      <component
        :is="getComponent"
        :steps="steps"
        @nexStep="nexStep"
        @prevStep="prevStep"
        @openDialog="openDialog"
      />
    </div>

    <DialogConfirm
      v-model="confirmDialog"
      :loading="loading"
      maxWidth="606"
      title="Ready to Begin Migration?"
      message="Make sure you've reviewed all your choices and understand what will be moved to Dialics 2.0. This is your final step before the migration begins"
      :confirmButtons="['Start migration', 'Cancel']"
      @actionSuccess="actionSuccess"
    />
  </div>
</template>

<script>
import MigrationProcessDesktopHeader
  from '@/views/migrationProcess/MigrationProcessDesktop/MigrationProcessDesktopHeader.vue'
import MigrationProcessDesktopStepOne
  from '@/views/migrationProcess/MigrationProcessDesktop/MigrationProcessDesktopStepOne.vue'
import MigrationProcessDesktopStepTwo
  from '@/views/migrationProcess/MigrationProcessDesktop/MigrationProcessDesktopStepTwo.vue'
import MigrationProcessDesktopStepThree
  from '@/views/migrationProcess/MigrationProcessDesktop/MigrationProcessDesktopStepThree.vue'
import DialogConfirm from '@/components/dialog/DialogConfirm.vue'

import { startMigration } from '@/api/migration-routes'

const LIST = {
  stepOne: {
    id: 1,
    label: 'Welcome'
  },
  stepTwo: {
    id: 2,
    label: 'Migration settings'
  },
  stepThree: {
    id: 3,
    label: 'Data migration'
  },
}

export default {
  name: 'MigrationProcessDesktop',
  components: { DialogConfirm, MigrationProcessDesktopHeader },
  props: {
    isInProgress: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    steps: LIST,
    activeStep: LIST.stepOne,
    confirmDialog: false,
    isFull: false,
    loading: false,
  }),
  computed: {
    getComponent() {
      const { id } = this.activeStep
      switch (id) {
        case LIST.stepOne.id:
          return MigrationProcessDesktopStepOne
        case LIST.stepTwo.id:
          return MigrationProcessDesktopStepTwo
        case LIST.stepThree.id:
          return MigrationProcessDesktopStepThree
      }
    }
  },
  methods: {
    prevStep(step) {
      this.activeStep = step
    },
    nexStep(step) {
      this.activeStep = step
    },
    openDialog(isFull) {
      this.isFull = isFull
      this.confirmDialog = true
    },
    closeDialog() {
      this.confirmDialog = false
    },
    async actionSuccess() {
      this.loading = true

      const { success, message } = await startMigration({
        full: this.isFull
      })

      if (success) {
        this.closeDialog()
        this.nexStep(LIST.stepThree)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    }
  },
  beforeMount() {
    this.activeStep = this.isInProgress ? LIST.stepThree : LIST.stepOne
  }
}
</script>

<style scoped lang="scss">

.migration-process-desktop {
  width: 670px;
  border: 1px solid var(--v-borderProcessMigration-base);
  border-radius: 12px;
}
</style>