<template>
  <div>
    <div class="mb-10 font-large titleMigration--text">
      Migration settings
    </div>

    <div class="mb-20 font-normal descriptionMigration--text">
        If you want to move all your data, pick "All data." If you prefer a fresh start, go with "Account"
    </div>

    <ToggleButton
      v-model="activeTab"
      :buttons="buttons"
    />

    <div class="mt-16 mb-20">
      <div
        class="mb-6 d-flex align-center"
        v-for="item in checkboxList"
        :key="item.id"
      >
        <PrimaryCheckbox
          :value="getValue(item)"
          disabled
        />

        <div class="font-normal descriptionMigration--text">{{ item.label }}</div>

        <TooltipAction
          v-if="showInfoIcon(item)"
          right
          :message="item.message"
          maxWidth="222"
          messagesClass="font-xs-mobile tooltipColor--text"
        >
          <template #activator>
            <v-icon
              class="ml-2"
              size="16"
              color="infoTextProcessMigration"
            >
              $infoIcon
            </v-icon>
          </template>
        </TooltipAction>
      </div>
    </div>

    <div class="d-flex justify-end flex-sm-row flex-column">
      <CancelButton
        class="mr-10"
        @click="prevStep"
      >
        Back
      </CancelButton>

      <ActionButton @click="openDialog">Next</ActionButton>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/buttons/ToggleButton.vue'
import ActionButton from '@/components/buttons/ActionButton.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import PrimaryCheckbox from '@/components/controls/PrimaryCheckbox.vue'
import TooltipAction from '@/components/tooltip/TooltipAction'

const buttons = {
  allData: {
    id: 1,
    name: 'All Data'
  },
  account: {
    id: 2,
    name: 'Account'
  }
}

export default {
  name: 'MigrationProcessDesktopStepTwo',
  components: { PrimaryCheckbox, CancelButton, ActionButton, ToggleButton, TooltipAction },
  props: {
    steps: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    buttons: Object.values(buttons),
    activeTab: buttons.allData.id,
    checkboxList: [
      { id: 1, valueAllData: true, valueAccount: true, label: 'Profile Balance' },
      { id: 2, valueAllData: true, valueAccount: true, label: 'Credentials' },
      { id: 3, valueAllData: true, valueAccount: true, label: 'Cards' },
      { id: 5, valueAllData: true, valueAccount: false, label: 'Campaigns', message: 'You can transfer Campaigns only when you choose the \'All Data\' option' },
      { id: 6, valueAllData: true, valueAccount: false, label: 'Phone Numbers',  message: 'You can transfer Phone Numbers only when you choose the \'All Data\' option' },
      { id: 7, valueAllData: true, valueAccount: false, label: 'Buyers', message: 'You can transfer Buyers only when you choose the \'All Data\' option' },
      { id: 8, valueAllData: true, valueAccount: false, label: 'Vendors', message: 'You can transfer Vendors only when you choose the \'All Data\' option' },
      { id: 9, valueAllData: false, valueAccount: false, label: 'Reporting', message: 'We can’t migrate it, but you can view this data in the old version after migration' },
    ]
  }),
  computed: {
    isAllData() {
      return this.activeTab === buttons.allData.id
    }
  },
  methods: {
    getValue({ valueAllData, valueAccount }) {
      return this.isAllData ? valueAllData : valueAccount
    },
    showInfoIcon({ valueAllData, valueAccount }) {
      return this.isAllData ? !valueAllData && !valueAccount : !valueAccount
    },
    prevStep() {
      this.$emit('prevStep', this.steps.stepOne)
    },
    openDialog() {
      this.$emit('openDialog', this.isAllData)
    },
  }
}
</script>