<template>
  <div class="px-8 pt-40 pb-20 pt-sm-100 pb-sm-50 d-flex flex-column align-center migration-process-mobile">
    <img
      class="mb-20 mb-sm-40 migration-process-mobile__img"
      src="/images/desctop.png"
    >

    <div class="mb-10 text-center titleMigration--text font-title-migration">
      Switch to desktop for migration
    </div>

    <div class="mb-40 text-center descriptionMigration--text font-normal">
      Please, log in to your account using a desktop device to begin the migration to the new version.
    </div>

    <ActionButton
      :class="{ 'width--full mt-auto': !checkSm }"
      @click="goToMigrationGuide"
    >
      {{ isPending ? 'Back to Guide' : 'Go to Dashboard' }}
    </ActionButton>
  </div>
</template>


<script>
import ActionButton from '@/components/buttons/ActionButton.vue'

export default {
  name: 'MigrationProcessMobile',
  components: { ActionButton },
  props: {
    isPending: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width > 600
    },
  },
  methods: {
    goToMigrationGuide() {
      if (this.isPending) {
        this.$router.push({ name: 'MigrationGuide' })
      } else {
        this.$router.push({ name: 'Dashboard' })
      }
    }
  }
}
</script>

<style scoped lang="scss">

.migration-process-mobile {
  height: calc(100vh - 80px);

  &__img {
    width: 80px;
    height: 80px;

    @media screen and (min-width: 600px) {
      width: auto;
      height: auto;
    }
  }
}
</style>