<template>
  <div class="my-30 px-35 py-35 mx-auto d-flex flex-column align-center migration-completed">
    <v-icon size="60">{{ isCompleted ? '$cloudSuccessIcon' : '$cloudFailIcon' }}</v-icon>

    <div class="mb-10 mt-6 font-large titleMigration--text">
      {{ isCompleted ? 'Migration successful!' : 'Migration failed!' }}
    </div>

    <div class="mb-20 font-normal descriptionMigration--text text-center">
        {{ description }}
    </div>

    <ActionButton @click="actionSuccess">
      {{ isCompleted ? 'Go to Dialics 2.0' : 'Talk to Support' }}
    </ActionButton>
  </div>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton.vue'

import { mapGetters } from 'vuex'
import {Types as authTypes} from '@/store/modules/auth'

export default{
  name: 'MigrationCompleted',
  components: { ActionButton },
  computed: {
    ...mapGetters({
      isCompleted: authTypes.getters.IS_COMPLETED_MIGRATION,
    }),
    description() {
      if (this.isCompleted) {
        return 'Congratulations, your data has been successfully migrated to Dialics 2.0! You\'re now ready to enjoy all the new features and improvements'
      }

      return 'We\'re sorry, but it looks like the migration didn\'t go as planned. Don\'t worry, your data in Dialics 1.0 is still safe. To resolve the issue, please contact support'
    }
  },
  methods: {
    actionSuccess() {
      if (this.isCompleted) {
        window.open('https://console.dialics.com/auth/login', '_blank')
      } else {
        this.openChat()
      }
    },
    openChat() {
      if (window.$crisp) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
      }
    }
  }
}
</script>

<style scoped lang="scss">

.migration-completed {
  width: 670px;
  border: 1px solid var(--v-borderProcessMigration-base);
  border-radius: 12px;
}
</style>