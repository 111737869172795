<template>
  <div v-if="checkMd">
    <MigrationProcessDesktop
      v-if="isInProgress || isPending"
      :isInProgress="isInProgress"
    />

    <MigrationCompleted v-else/>
  </div>

  <MigrationProcessMobile
    v-else
    :isPending="isPending"
  />
</template>

<script>
import MigrationProcessDesktop from '@/views/migrationProcess/MigrationProcessDesktop/MigrationProcessDesktop.vue'
import MigrationProcessMobile from '@/views/migrationProcess/MigrationProcessMobile.vue'
import MigrationCompleted from '@/views/migrationProcess/MigrationCompleted.vue'

import { mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'MigrationProcess',
  components: { MigrationCompleted, MigrationProcessMobile, MigrationProcessDesktop },
  computed: {
    ...mapGetters({
      isInProgress: authTypes.getters.IS_IN_PROGRESS_MIGRATION,
      isPending: authTypes.getters.IS_PENDING_MIGRATION,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1024
    },
  }
}
</script>
