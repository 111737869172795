<template>
  <div>
    <div class="mb-10 font-large titleMigration--text">
      Start migration to dialics 2.0
    </div>

    <div class="mb-20 font-normal descriptionMigration--text">
        The information about your vendors and other users will be transferred. Keep working with the same people without any new setups
    </div>

    <div class="mb-15 px-10 py-10 d-flex migration-process-desktop-step-one__info">
      <v-icon class="mr-3" size="22" color="infoTextProcessMigration">$infoIcon</v-icon>

      <div class="font-xs-normal infoTextProcessMigration--text">
          After migration, you can only view data in the old version. No managing or editing is allowed. The current version will be available until {{ ACCOUNT_MIGRATION_DATE_END }}
      </div>
    </div>

    <div class="d-flex align-center justify-space-between">
      <div
        class="d-flex"
        @click="onChangeConfirm"
      >
        <PrimaryCheckbox
          v-model="isConfirm"
          @click.stop
        />

        <div class="mr-8 font-xs-normal descriptionMigration--text">
            I have read and agree to all provided information in the

            <span
            class="primary--text text-decoration-underline cursor-pointer"
            @click.stop="goToMigrationGuide"
          >
            Migration guide
          </span>
        </div>
      </div>

      <ActionButton
        :disabled="!isConfirm"
        @click="nexStep"
      >
        Next
      </ActionButton>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton.vue'
import PrimaryCheckbox from '@/components/controls/PrimaryCheckbox.vue'

import { ACCOUNT_MIGRATION_DATE_END } from '@/constants/accountMigrationStatus'

export default {
  name: 'MigrationProcessDesktopStepOne',
  components: { PrimaryCheckbox, ActionButton },
  props: {
    steps: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isConfirm: false,
    ACCOUNT_MIGRATION_DATE_END: ACCOUNT_MIGRATION_DATE_END.dateOne
  }),
  methods: {
    onChangeConfirm() {
      this.isConfirm = !this.isConfirm
    },
    goToMigrationGuide() {
      this.$router.push({ name: 'MigrationGuide' })
    },
    nexStep() {
      this.$emit('nexStep', this.steps.stepTwo)
    },
  }
}
</script>

<style scoped lang="scss">

.migration-process-desktop-step-one {

  &__info {
    border-radius: 5px;
    background: var(--v-infoProcessMigration-base);
  }
}

.cursor-pointer {
  cursor: pointer;
  transition: all;

  &:hover {
    opacity: 0.9;
  }
}
</style>